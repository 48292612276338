<template>
  <div class="phoneVerfy-box">
    <nav-bar :title="$t(isPhone ? isEdit ? '修改手機號' : '手機號' : '綁定手機號')" :isFixed="true" :goBack="goBack"></nav-bar>
    <div class="box-main">
      <div v-if="isPhone">
        <van-form @submit="nextSubmit()">
          <div class="tigs">{{ $t('當前綁定手機號') }}</div>
          <div class="phone">{{ getphone }}</div>
          <div v-if="isEdit" style="margin-top: 24px;">
            <van-field v-model="CaptchaNewCode1" type="number" :placeholder="$t('請輸入驗證碼')">
              <template #button>
                <div class="btn-getcode" v-if="countDown !== 0">
                  {{ countDown }}s
                </div>
                <div class="btn-getcode" v-else @click="getPhoneCode('2')">
                  {{ $t("獲取驗證碼") }}
                </div>
              </template>
            </van-field>
            <div class="error" v-if="isCode">
              {{ $t("請輸入驗證碼") }}
            </div>
          </div>
          <div style="margin: 40px 0 0;">
            <van-button color="#90D12E" block type="primary" v-if="!isEdit" @click="editPhone">
              {{ $t('修改手機號') }}
            </van-button>
            <van-button v-else color="#90D12E" block type="primary" native-type="submit">
              {{ $t('下一步') }}
            </van-button>
            <van-button v-if="!isEdit" style="color: #666666;margin-top: 12px;" color="#EEEEEE" block @click="goBack">
              {{ $t('返回') }}
            </van-button>
          </div>
        </van-form>
      </div>
      <div v-else>
        <van-form @submit="onPhoneSubmit()">
          <div class="title">{{ $t('請輸入手機號碼') }}</div>
          <div class="areaCode" @click="show = true">{{ phoneLabel }}<img src="@/assets/icons/arrow-rightactive.png"
              alt="" />
          </div>
          <van-field v-model="params.phone" type="number" :placeholder="$t('請輸入手機號碼')" />
          <div class="error" v-if="verifyobj.phone">
            {{ $t("請輸入") }}{{ $t("有效電話號碼") }}
          </div>
          <van-field v-model="CaptchaNewCode" type="number" :placeholder="$t('請輸入驗證碼')">
            <template #button>
              <div class="btn-getcode" v-if="countDown !== 0">
                {{ countDown }}s
              </div>
              <div class="btn-getcode" v-else @click="getNewPhoneCode('2')">
                {{ $t("獲取驗證碼") }}
              </div>
            </template>
          </van-field>
          <div class="error" v-if="isCode">
            {{ $t("請輸入驗證碼") }}
          </div>
          <div style="margin: 30px 0 0;">
            <van-button color="#90D12E" block type="primary" native-type="submit">
              {{ $t('綁定手機號') }}
            </van-button>
          </div>
        </van-form>
      </div>
    </div>
    <van-popup v-model:show="show" position="bottom" :close-on-click-overlay="false"
      :style="{ height: '40%',borderRadius: '16px 16px 0 0' }">
      <div class="titleArea">
        <div></div>
        <div>{{ $t('國家地區') }}</div>
        <div class="close">
          <img @click="show = false" style="width: 100%" src="@/assets/icons/ic_Close@2x.png" alt="" />
        </div>
      </div>
      <div class="phone-list">
        <div :class="['phone-item',{ actvie: item.code == params.areaCode }]" v-for="item in phoneCode" :key="item.code"
          @click="handleAreaCode2(item)">
          <div class="phone-text">{{ item.name }}</div>
          <img class="phone-check" v-if="item.code == params.areaCode" src="@/assets/icons/check.png" alt="" />
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import JSEncrypt from "jsencrypt"
import { mapState } from "vuex"
import { getCaptchaNew, payUpdatePhone } from "@/api/user"

export default {
  name: "phoneVerify",
  data () {
    return {
      isEdit: false,
      show: false,
      CaptchaNewCode: null,
      CaptchaNewCode1: null,
      areaCode: null,
      countDown: 0,
      verifyList: [],
      params: {
        areaCode: null,
        phone: null,
        password: null,
        repassword: null,
        phoneCaptcha: null
      },
      verifyobj: {
        phone: "",
      },
      isPhone: false,
      isCode: false,
      phoneLabel: null,
      timer: null
    }
  },
  async created () {
    await this.$store.dispatch("user/getUserInfo")
    await this.$store.dispatch("user/getPhoneCode")
    this.params.areaCode = this.phoneCode[0]?.code
    this.phoneLabel = this.phoneCode[0]?.name
  },
  watch: {
    'userInfo.phone': {
      handler (val) {
        this.isPhone = val ? true : false
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapState("user", ["userInfo", "phoneCode"]),
    getphone () {
      const str = `${this.userInfo.areaCode}-${this.userInfo.phone}`
      const before = str.slice(0, 5)
      const after = str.slice(str.length - 2, str.length)
      const stars = '*'.repeat(6)
      return before + stars + after
    }


  },
  methods: {
    goBack () {
      if (!this.isPhone&&this.userInfo.phone) {
        this.isPhone = true
      } else {
        this.$router.back()
      }
    },
    editPhone () {
      this.isEdit = true
    },
    // 有绑定手机号下一步
    async nextSubmit () {
      // this.params.phone = this.userInfo.phone
      // this.params.areaCode = this.userInfo.areaCode
      // 验证验证码
      const CaptchaNewCode = this.CaptchaNewCode1.trim()
      const regex = /\d{6}/
      if (!CaptchaNewCode || !regex.test(CaptchaNewCode) || CaptchaNewCode.length > 6) {
        this.isCode = true
        return
      }
      // 有绑定手机验证验证码
      // let result = await payvValidatePhoneCaptcha({ phone: this.params.phone, areaCode: this.params.areaCode, captcha: this.CaptchaNewCode,isregiested:true,type:"payPwd"  })
      // if (result.resultID !== 1200) return
      this.isCode = false
      clearInterval(this.timer) // 清除定时器
      this.countDown = 0
      this.isPhone = false
    },
    // 绑定过手机号获取验证码
    async getPhoneCode (type) {
      const publicKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC/TS09a8Uez9/XfkDdK+Exx3ltVxrUlCjNPqRheMB/63iHyjx5k+N/48Nh9NFdCQKLEV/WxHQoszEBEh/sFofQuAx1wB9bcOtD98DT8Wkd53XmojxVfhtsVjeI/IBMim56mrO/LcKQDwc/6BsbFvilde002wx3CQ91OCw8XMptTwIDAQAB`
      let obj = {
        phone: this.userInfo.phone,
        type: "updatePhone",
        areaCode: this.userInfo.areaCode,
      }
      let jsEncrypt = new JSEncrypt()
      jsEncrypt.setPublicKey(publicKey)
      let dataToEncrypt = JSON.stringify(obj)
      const res = await getCaptchaNew({
        id: jsEncrypt.encrypt(dataToEncrypt)
      })
      if (res.resultID !== 1200) return
      if (type == "2") {
        this.countDown = 60
        this.getTime()
      }
    },
    // 未绑定过手机号获取验证码
    async getNewPhoneCode (type) {
      const isSubmit = await this.handleExamine()
      if (!isSubmit) return

      const publicKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC/TS09a8Uez9/XfkDdK+Exx3ltVxrUlCjNPqRheMB/63iHyjx5k+N/48Nh9NFdCQKLEV/WxHQoszEBEh/sFofQuAx1wB9bcOtD98DT8Wkd53XmojxVfhtsVjeI/IBMim56mrO/LcKQDwc/6BsbFvilde002wx3CQ91OCw8XMptTwIDAQAB`
      let obj = {
        phone: this.params.phone,
        type: "newPhone",
        areaCode: this.params.areaCode,
      }
      let jsEncrypt = new JSEncrypt()
      jsEncrypt.setPublicKey(publicKey)
      let dataToEncrypt = JSON.stringify(obj)
      const res = await getCaptchaNew({
        id: jsEncrypt.encrypt(dataToEncrypt)
      })
      if (res.resultID !== 1200) return
      if (type == "2") {
        this.countDown = 60
        this.getTime()
      }
    },
    // 无手机号绑定下一步
    async onPhoneSubmit () {
      const isSubmit = await this.handleExamine()
      if (!isSubmit) return
      // 验证验证码
      const CaptchaNewCode = this.CaptchaNewCode.trim()
      const regex = /\d{6}/
      if (!CaptchaNewCode || !regex.test(CaptchaNewCode) || CaptchaNewCode.length > 6) {
        this.isCode = true
        return
      }
      let result = await payUpdatePhone({ phone: this.params.phone, areaCode: this.params.areaCode, phoneCaptcha: this.CaptchaNewCode, })
      if (result.resultID !== 1200) return
      this.$router.back()
      this.$toast(this.$t('操作成功'))
    },
    getTime () {
      this.timer = setInterval(() => {
        this.countDown--
        if (this.countDown === 0) {
          clearInterval(this.timer) // 清除定时器
          this.countDown = 0
        }
      }, 1000)
    },
    // 验证
    handleExamine () {
      // const regxEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
      let regxPhone = /^1[3456789]\d{9}$/
      // 密码
      const regex = /\d{6}/

      switch (this.params.areaCode) {
        case '+852': // 香港
          regxPhone = /^(?:\+852)?[0-9]{8}$/
          break
        case '+86': // 中国大陆
          regxPhone = /^(?:\+86)?1[3-9][0-9]{9}$/ // 11位手机号，以1开头
          break
        case '+1': // 美国/加拿大
          regxPhone = /^(?:\+1)?[0-9]{10}$/ // 10位手机号
          break
        case '+853': // 澳门
          regxPhone = /^(?:\+853)?[0-9]{8}$/ // 8位手机号
          break
        // 可以添加其他国家的区号和对应的正则表达式
        default:
          regxPhone = /^(?:\+852)?[0-9]{8}$/
      }
      this.verifyList = []
      if (!regxPhone.test(this.params.phone)) {
        this.verifyobj["phone"] = true
      } else {
        delete this.verifyobj["phone"]
      }

      const keys = Object.keys(this.verifyobj)

      keys.forEach(item => {
        this.validatoFormFormcheck(item)
      })
      for (const key in this.verifyobj) {
        this.verifyobj[key] = ""
      }
      this.verifyList.forEach(item => {
        this.verifyobj[item] = true
      })
      if (this.verifyList.length > 0) {
        return false
      } else {
        return true
      }
    },
    validatoFormFormcheck (label) {
      if (!this.params[label]) {
        this.verifyList.push(label)
      } else {
        const index = this.verifyList.findIndex(item => item === label)
        if (index != -1) {
          this.verifyList.splice(index, 1)
        }
      }
    },
    // 选择国家地区
    handleAreaCode2 (item) {
      this.show = false
      this.params.areaCode = item.code
      this.phoneLabel = item.name
    },
  }
}
</script>
<style lang="scss" scoped>
.phoneVerfy-box {
  padding-top: 44px;
  background: #FFFFFF;
  height: 100vh;

  .box-main {
    padding: 40px 26px 0;

    .title {
      font-weight: 500;
      font-size: 17px;
      color: #1A1A1A;
      line-height: 24px;
    }

    .tigs {
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 22px;
    }

    .phone {
      margin-top: 12px;
      font-weight: bold;
      font-size: 20px;
      color: #1A1A1A;
      line-height: 30px;

    }

    .btn-getcode {
      font-weight: 400;
      font-size: 16px;
      color: #3491FA;
      line-height: 24px;
    }

    .error {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #f82207;
      line-height: 18px;
      padding-left: 12px;
      margin-top: 1px;
    }

    .areaCode {
      margin: 20px 0;
      font-weight: 400;
      font-size: 15px;
      color: #1A1A1A;
      line-height: 24px;
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
      }

    }


    :deep(.van-field) {
      border-bottom: 1px solid #F8F8F8;
    }
  }

  .titleArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 16px 0;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 17px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 24px;

    .close {
      width: 20px;
    }
  }

  .phone-list {
    padding: 20px 16px;

    .actvie {
      border: 2px solid #90d12e !important;
    }

    .phone-item {
      padding: 20px;
      position: relative;
      background: #f8f8f8;
      border-radius: 8px 8px 8px 8px;
      margin-bottom: 12px;
      border: 2px solid transparent;

      .phone-text {
        font-size: 16px;
        color: #1a1a1a;
        line-height: 16px;
        text-align: center;
      }

      .phone-check {
        position: absolute;
        width: 24px;
        bottom: -1px;
        right: -1px;
      }
    }
  }
}
</style>